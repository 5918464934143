<template lang="pug">
	b-dropdown.dropdown.w-100.dropdown-plans.animated(:id="`${id}-dropdown-plans`" menu-class="p-0 m-0" :class="{'z-index-1032 ' : overlay}" :disabled='list.length == 0')
		template(#button-content)
			span.b3 {{ $t('promotions.plans') }}
			span.d-flex.dropdown-plans__length {{ list.length }}
		b-dropdown-item.dropdown-plans__item(v-for='(item, i) in list' :key='i' :class="{'is-resources' : resources}")
			.d-flex.justify-content-between.align-items-center.dropdown-plans__content(:class="{'is-resources' : resources}")
				.d-flex.align-items-center.mr-3
					span(v-html="item.title")
					template(v-if="item.status == 'ARCHIVE' && !resources")
						svg.ml-10.flex-shrink-0(width='27' height='26' viewbox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg')
							rect(y='0.5' width='26' height='26' rx='4' fill='#EDEDEF')
							path(d='M18.625 12.1641V18.7491C18.625 20.2491 18.25 20.9991 16.375 20.9991H9.625C7.75 20.9991 7.375 20.2491 7.375 18.7491V12.1641' stroke='#8292A1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
							path(d='M18.25 6C19.75 6 20.5 6.75 20.5 8.25V9.75C20.5 11.25 19.75 12 18.25 12H7.75C6.25 12 5.5 11.25 5.5 9.75V8.25C5.5 6.75 6.25 6 7.75 6H18.25Z' stroke='#8292A1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
							path(d='M11.6348 15H14.3648' stroke='#8292A1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

				.d-flex.align-items-center.text-nowrap.dropdown-plans__prices(v-if="!resources")
					span.dropdown-plans__price(v-if="discount") {{ planPriceWithDiscount(item) }}
					span.dropdown-plans__price(v-if="!discount") {{ item.price.price() }}
					span.text-nowrap.dropdown-plans__currency(v-html="item.price.currencyTitle()")
					span.dropdown-plans__price.text-nowrap.dropdown-plans__price--old(v-if="discount" v-html="`${item.price.priceWithCurrency()}`")
			.d-flex.justify-content-between.align-items-center.flex-wrap
				span.b4.dropdown-plans__discount(v-if="discount" :class="{'is-comebacker' : type == 'comebacker'}") -{{ discount }}%
				span.dropdown-plans__date(v-if="item.period" :class="resources ? 'ml-0 mr-auto b4 is-resources' : 'b3'") {{ $t('plan.periods.' + item.period.title) }}
				.d-flex.align-items-center.text-nowrap(v-if="resources")
					span.dropdown-plans__price(v-if="discount") {{ Math.round(item.price.price() / 100 * (100 - discount)) }}
					span.dropdown-plans__price(v-if="!discount" :class="resources ? 'h3' : ''") {{ item.price.price() }}
					span.ml-1.text-nowrap.dropdown-plans__currency(v-html="item.price.currencyTitle()")
					span.ml-2.dropdown-plans__price.text-nowrap.dropdown-plans__price--old(v-if="discount" v-html="`${item.price.priceWithCurrency()}`")
</template>

<script>
export default {
	name: 'DropdownPlans',
	props: {
		list: {
			default: () => [],
			type: Array
		},
		id: {
			default: null,
			type: String
		},
		discount: {
			default: null,
			type: Number
		},
		resources: {
			default: false,
			type: Boolean
		},
		type: {
			default: '',
			type: String
		}
	},
	data: () => ({
		overlay: false,
		windowInnerHeight: null,
		scrollbarWidth: 0
	}),
	created() {
		window.addEventListener('click', () => {
			if(this.overlay === true) {
				this.onClose();
			}
		}),
		this.windowInnerHeight = window.innerHeight;
		window.addEventListener('resize', () => {
			this.windowInnerHeight = window.innerHeight;
		})
	},
	mounted() {
		this.$root.$on('bv::dropdown::show', bvEvent => {
			if(bvEvent.componentId == `${this.id}-dropdown-plans`) {
				document.body.classList.add('overlay');
				this.overlay = true;
				if(!this.isMobile) {
					document.querySelector('body').style.paddingRight = this.scrollbarWidth + 'px';
					document.body.classList.remove('overlay--mobile');
				} else {
					document.body.classList.add('overlay--mobile');
				}
			}
		})
		this.$root.$on('bv::dropdown::hide', bvEvent => {
			if(bvEvent.componentId == `${this.id}-dropdown-plans`) {
				document.body.classList.remove('overlay');
				this.overlay = false;
				document.querySelector('body').style.paddingRight = 0;
			}
			if (!this.isMobile) {
				document.body.classList.remove('overlay--mobile');
			}
		}),
		this.getScrollbarWidth();
	},
	methods: {
		onClose() {
			document.body.classList.remove('overlay');
			this.overlay = false;
			document.querySelector('body').style.paddingRight = 0;
		},
		getScrollbarWidth() {
			// Creating invisible container
			const outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll'; // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement('div');
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			this.scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);
		},
		planPriceWithDiscount(plan) {
			if (plan.price && plan.price.isCurrencyRUB()) {
				return Math.round(plan.price.price() / 100 * (100 - this.discount));
			} else {
				return (plan.price.price() / 100 * (100 - this.discount)).toFixed(2).replace('.00', '');
			}
		}
	}
}
</script>
<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.overlay--mobile {
	position: fixed;
}
.dropdown-plans {
	height: 48px;
	margin-bottom: 2px;

	&.z-index-1032 {
		z-index: 1032;
	}

	&:hover,
	&:active,
	&:focus {
		button {
			background-color: var(--foreground-color) !important;
			color: var(--main-text-color) !important;
			box-shadow: var(--btn-box-shadow) !important;
		}

		.dropdown-plans__length {
			background-color: var(--bg-back);
		}
	}

	&__length {
		justify-content: center;
		min-width: 30px;
		padding: 1.5px 13.5px;
		font-size: 14px;
		line-height: 22px;
		color: var(--primary-text-color) !important;
		background-color: var(--foreground-color);
		border-radius: 6px !important;
		transition: var(--animation-time-short);
		margin-left: 10px;
	}

	&__prices {
		margin-top: 2px;
	}

	&__price {
		font-size: 24px;
		line-height: 24px;

		&--old {
			font-size: 12px;
			line-height: 14px;
			color: var(--grey-text-color);
			text-decoration: line-through;
			margin-left: 6px;
		}
	}

	&__currency {
		font-size: 14px;
		line-height: 24px;
		margin-top: 6px;
		margin-left: 2px;
	}

	&__discount {
		padding: 1.5px 6.5px;
		color: var(--wr-text-color);
		background-color: var(--red-bg-color);
		border-radius: 6px;

		&.is-comebacker {
			background-color: var(--brand-bg-discount);
			color: #AC7DD1;
		}
	}

	&__date {
		padding: 1.5px 6.5px;
		color: var(--primary-text-color);
		background-color: var(--primary-bg-color);
		border-radius: 6px;
		margin-left: auto;

		&.is-resources {
			border-radius: 8px;
			padding: 4px 10px;
		}
	}

	&__item {
		margin-top: 17px;
		margin-bottom: 20px;

		&:last-of-type {
			margin-bottom: 14px;
		}

		&.is-resources {
			margin-top: 18px;
			margin-bottom: -3px;

			&:last-of-type {
				margin-bottom: 12px;
			}
		}
	}

	&__content {
		margin-bottom: 6px;

		&.is-resources {
			margin-bottom: 9px;
		}
	}

	button {
		justify-content: flex-start;
		height: 48px;
		padding: 13px 17px 13px 16px !important;
		border: 1px solid transparent !important;
		background-color: var(--bg-back) !important;
		border-radius: var(--border-radius-rounded) !important;
		color: var(--main-text-color) !important;
		box-shadow: none !important;
	}

	.dropdown-toggle {
		border: 1px solid transparent !important;
		border-bottom: none !important;

		&::after {
			width: 18px;
			height: 18px;
			background: url('/assets/img/icons/arrow.svg');
			margin-left: auto !important;
			border: none !important;
		}
	}

	&.show .dropdown-toggle {
		position: relative;
		border-color: var(--border-line) !important;
		background-color: var(--foreground-color) !important;
		color: var(--main-text-color) !important;

		&::after {
			transform: rotate(180deg);
		}

		.dropdown-plans__length {
			background-color: var(--bg-back);
		}
	}

	&.show button {
		box-shadow: var(--box-shadow-hover) !important;
	}

	.dropdown-menu {
		top: 8px !important;
		right: 0 !important;
		padding: 0 15px !important;
		max-height: 340px !important;
		background-color: var(--foreground-color) !important;
		border-radius: var(--border-radius-rounded) !important;
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		box-shadow: var(--box-shadow-plans) !important;
		border: 1px solid transparent !important;
		border-top: none !important;
		border-bottom: none !important;
	}

	&.show ul li:first-child:before {
		content: none !important;
	}

	.dropdown-item {
		display: flex;
		flex-direction: column;
		padding: 10px 12px 10px 11px !important;
		color: var(--main-text-color) !important;
		box-shadow: var(--user-project-shadow) !important;
		border-radius: var(--border-radius-rounded) !important;
		background-color: var(--foreground-color);
	}

	&.animated {
		.dropdown-menu {
			overflow: auto;
			display: block!important;
			max-height: 0!important;
			//transition: max-height var(--animation-time-short) ease-in-out;

			&:not(.show) {
				padding: 0;
				border: none;
			}

			&.show {
				//transition: max-height var(--animation-time-short) ease-in-out;
				max-height: 360px!important;
				background-color: var(--foreground-color) !important;
				border: 1px solid var(--border-line) !important;
				border-top: none !important;
			}
		}
	}
}
</style>
